<template>
    <div>
        <b-row>
            <b-col md="6">
                <CCard>
                    <CCardHeader color="primario" text-color="white">
                        <i class="fas fa-chart-bar"></i> <span class="h5"> Estadísticas</span>
                    </CCardHeader>
                    <CCardBody>
                        <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA DE ITINERARIOS:
                        </span>
                        <hr>
                        <b-row class="justify-content-center text-center">
                            <b-col cols="12" class=" my-2 icon-custom">
                                <i style="inline-size: auto" class="fas fa-cubes fa-3x pb-1"></i>
                                <br>
                                <span class="lg-numero">{{listIinerario.length}}</span>
                                <br>
                                <span class="text-muted">Itinerarios Registrados</span>
                            </b-col>
                        </b-row>
                    </CCardBody>
                </CCard>
            </b-col>
            <b-col md="6">
                <CCard>
                    <CCardHeader color="primario" text-color="white">
                        <i class="fas fa-cog"></i><span class="h5"> Itinerarios</span>
                    </CCardHeader>
                    <CCardBody>
                        <b-row class="justify-content-center text-center">
                            <b-col cols="6" class="my-2">
                                <b-button block variant="custom" :to="{name: 'Gestión de itinerarios'}">
                                    <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Nuevo Registro</span>
                                </b-button>
                            </b-col>
                        </b-row>
                    </CCardBody>
                </CCard>
            </b-col>
            <b-col md="12">
                <CCard>
                    <CCardHeader color="primario" text-color="white">
                        <i class="fas fa-list fa-md"></i><span class="h5"> Lista de Itinerarios Registrados </span>
                        <b-button v-if="listIinerario.length >= 1" class="float-right" variant="success" size="sm" @click="exportDataToExcel">
                        <i class="fas fa-download mr-1"></i>
                        Exportar</b-button>
                    </CCardHeader>
                    <CCardBody>
                        <b-row>
                            <b-col md="3">
                                <b-form-group label="Registros por página" class="text-muted">
                                    <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Busqueda:" class="text-muted">
                                    <b-input-group size="xl">
                                        <b-input-group-prepend is-text>
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </b-input-group-prepend>
                                        <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col md="12">
                                <b-table bordered hover show-empty mediun responsive outlined :items="listIinerario" :fields="camposItinerario" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                    <template v-slot:cell(guiaPrincipal)="data">
                                        <template v-if="listUsuarios.find(x => x.idGuia == data.item.idGuia) == null">
                                            {{''}}
                                        </template>
                                        <template v-else>
                                            {{listUsuarios.find(x => x.idGuia == data.item.idGuia).nombre | truncate(50)}}
                                        </template>
                                    </template>
                                    <template v-slot:cell(cantDias)="data">
                                        {{data.item.listGestionItinerario.length}}
                                    </template>
                                    <template v-slot:cell(opciones)="param">
                                        <b-button size="sm" v-c-tooltip.hover.click="'Ver detalles'" :to="{name: 'Gestión de itinerarios', params: {id: param.item.id}}" variant="dark" class=" mr-1 mb-1">
                                            <span class="btn-inner--icon">
                                                <i class="fas fa-search my-0 mx-0"></i>
                                            </span>
                                        </b-button>

                                        <b-button size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarItinerario(param)" variant="danger">
                                            <span class="btn-inner--icon">
                                                <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                            </span>
                                        </b-button>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                    </CCardBody>
                    <CCardFooter>
                        <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                    </CCardfooter>
                </CCard>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import firebase from 'firebase';
const dbUsuario = firebase.firestore().collection('users');
const dbItinerario = firebase.firestore().collection('itinerarios');
const dbComida = firebase.firestore().collection('tipoComida');
import XLSX from 'sheetjs-style-v2';

export default {
    data() {
        return {
            listIinerario: [],
            listUsuarios: [],

            listComida: [],

            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            filter: null,
            filterOn: [],

            camposItinerario: [
                {
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "guiaPrincipal",
                    label: "Guia Principal",
                    class: "text-center",
                },
                {
                    key: "cantDias",
                    label: "Cant. de Dias",
                    class: "text-center",
                },
                // {
                //     key: "fecha",
                //     label: "Fecha",
                //     class: "text-center",
                // },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                },
            ],
        }
    },
    methods: {
        exportDataToExcel() {
            let now = moment().format('YYYYMMDDHHmm');

            const valUpper = text => {
                if (text != null && text !== undefined) {
                    return text.toString().toUpperCase();
                }
                return '';
            };

            const valDate = date => {
                if (date && date !== undefined) {
                    return moment(date).format('DD-MM-YYYY')
                }
                return '';
            };

            let dataExcel = this.listIinerario.map(data => {
                return {
                    A: valUpper(this.listUsuarios && this.listUsuarios !== undefined ?
                        this.listUsuarios.filter(x => x.idGuia == data.idGuia).length > 0 ?
                        this.listUsuarios.filter(x => x.idGuia == data.idGuia)[0].nombre : '' : ''),
                    B: data['listGestionItinerario'] && data['listGestionItinerario'] !== undefined ? data['listGestionItinerario'].length : 0,
                    C: data['listGestionItinerario'] && data['listGestionItinerario'] !== undefined && data['listGestionItinerario'].length > 0 ? data['listGestionItinerario'].map(elx => {
                        return `${valUpper(elx['dia'])} - DESCRIPCIÓN: ${valUpper(elx['descripcion'])} - DISTANCIA RECORRIDA: ${valUpper(elx['recorrido'])} - COMIDAS: ${elx['comida'] && elx['comida'] !== undefined && elx['comida'].length > 0 ? elx['comida'].map(com => {
                            return valUpper(this.listComida && this.listComida !== undefined ? 
                            this.listComida.filter(x => x.id == com).length > 0 ?
                            this.listComida.filter(x => x.id == com)[0].comida : '' : '')
                        }).join(', ') : ''} - ${elx['listActividad'].length <= 1 ? 'ACTIVIDAD:' : 'ACTIVIDADES:'} ${elx['listActividad'] && elx['listActividad'] !== undefined && elx['listActividad'].length > 0 ? elx['listActividad'].map((act,index) => {
                            return ` ACTIVIDAD ${index+1} --> HORA INICIO: ${valUpper(act['horaInicio'])} - HORA FIN: ${valUpper(act['horaFin'])} - ACTIVIDAD: ${valUpper(act['actividad'])}`
                        }).join('; ') : ''}`
                    }).join('\n') : ''
                };
            });

            let dataWS = XLSX.utils.json_to_sheet(dataExcel, {
                origin: 'A4',
            });

            let dataWSMerges = [{
                s: {
                    r: 1,
                    c: 1,
                },
                e: {
                    r: 1,
                    c: 3,
                },
            }];

            dataWS['!merges'] = dataWSMerges;

            let dataWSCols = [
                {
                    wch: 30, //A
                },
                {
                    wch: 15, //B
                },
                {
                    wch: 400, //C
                },
            ];

            dataWS['!cols'] = dataWSCols;

            for(let i of [
                {
                    f: 'B2',
                    v: 'ITINERARIOS',
                },
                {
                    f: 'C2',
                },
                {
                    f: 'D2',
                },
                {
                    f: 'A4',
                    v: 'NOMBRE DEL GUÍA',
                },
                {
                    f: 'B4',
                    v: 'CANT. DIAS',
                },
                {
                    f: 'C4',
                    v: 'INFORMACIÓN DE DIAS',
                },
            ]){
                XLSX.utils.sheet_add_aoa(dataWS, [
                    [i.v || '']
                ], {
                    origin: i.f,
                });

                dataWS[i.f].s = {
                    numFmt: i.w || 'general',
                    fill: {
                        fgColor: {
                            rgb: i.b ? 'FFFFFF' : 'F2F2F2',
                        },
                    },
                    font: {
                        name: 'arial',
                        sz: 8,
                        bold: i.b ? false : true,
                        color: {
                            rgb: i.b ? '000000' : '1F497D',
                        },
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                    },
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center',
                        wrapText: 1,
                    },
                };
            }

            for (let k in dataExcel) {
                for (let i of Object.keys(dataExcel[0])) {
                    dataWS[`${i}${(parseInt(k) + 5).toString()}`].s = {
                        numFmt: '@',
                        fill: {
                            fgColor: {
                                rgb: 'FFFFFF',
                            },
                        },
                        font: {
                            name: 'arial',
                            sz: 8,
                            color: {
                                rgb: '000000',
                            },
                        },
                        border: {
                            top: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                            bottom: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                            right: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                            left: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                        },
                        alignment: {
                            horizontal: 'center',
                            vertical: 'center',
                        },
                    };
                }
            }

            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, dataWS, 'ITINERARIOS');
            XLSX.writeFile(wb, `ITINERARIOS ${now}.xlsx`);
        },
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        formatearFecha(fecha,formato){
            return moment(fecha).format(formato);
        },
        listarComidas() {
            let me = this;

            dbComida
            .where("estado" , "==" , 2)
            .onSnapshot((querySnapshot) => {
                me.listComida = [];
                querySnapshot.forEach((doc) => {
                    me.listComida.push({
                        id: doc.id,
                        comida: doc.data().comida,
                    });
                });
            });
        },
        listarUsuarios() {
            let me = this;

            dbUsuario
            .where("estado" , "==" , 2)
            .where("idCliente", "==", String(me.$store.state.user.idCliente))
            .where("rol","==","guia")
            .onSnapshot((querySnapshot) => {
                me.listUsuarios = [];
                querySnapshot.forEach((doc) => {
                    me.listUsuarios.push({
                        idGuia: doc.id,
                        nombre: doc.data().nombre + ' ' + doc.data().apellidoPaterno + ' ' + doc.data().apellidoMaterno,
                    });
                });
            });
        },
        listarItinerario() {
            let me = this;

            dbItinerario
            .where("estado" , "==" , 2)
            .where("idCliente", "==", String(me.$store.state.user.idCliente))
            .onSnapshot((querySnapshot) => {
                me.listIinerario = [];
                querySnapshot.forEach((doc) => {
                    me.listIinerario.push({
                        id: doc.id,
                        fechaCreacion: doc.data().fechaCreacion,
                        idGuia: doc.data().idGuia,
                        listGestionItinerario: doc.data().listGestionItinerario,
                    });
                });
                me.listIinerario.sort((a,b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0));
            });
        },
        eliminarItinerario(param) {
            let me = this;
            
            me.$swal.fire({
                title: '¿Estas seguro de eliminar?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    dbItinerario.doc(param.item.id).update({
                        estado: 1,
                        fechaEliminar: firebase.firestore.Timestamp.fromDate(new Date()),
                    })
                    .then(() => {
                        me.swat('success', 'Eliminado satisfactoriamente')
                        // me.disable = false;
                    })
                    .catch((error) => {
                        // me.disable = false;
                        me.swat('error', 'Algo salió mal!')
                    });

                }
            });
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title,
            });
        },
    },
    computed: {

    },
    watch: {

    },
    mounted() {
        if (this.$store.state.user) {
            this.listarUsuarios();
            this.listarItinerario();
            this.listarComidas();
        }
    },
}
</script>